import React from "react";
import AccountInfo from "./AccountInfo";
import AccountStatus from "./AccountStatus";
import BillingInfo from "./BillingInfo";
import Login from "./Login";

function UserAccountInfo({ user, vault, activities }) {
  return (
    <>
      <div className="columns">
        <div className="column">
          <div className="tile is-ancestor">
            <div className="tile is-parent is-vertical mt-2">
              <div className="tile mb-3">
                <AccountInfo user={user} vault={vault} />
              </div>
              <div className="tile">
                <BillingInfo user={user} vault={vault} />
              </div>
            </div>
            <div className="tile is-parent is-vertical mt-2">
              <div className="mb-3">
                <AccountStatus user={user} />
              </div>
              <div className="tile">
                <Login user={user} activities={activities} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

UserAccountInfo.propTypes = {};

export default UserAccountInfo;
