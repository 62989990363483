import React, { useState, useEffect } from "react";
import Panel from "components/shared/panel/Panel";
import Info from "./info/Info";
import moment from "moment";

function AccountInfo({ user, vault }) {
  const [createdAt, setCreatedAt] = useState();

  useEffect(() => {
    if (!user) return;
    setCreatedAt(
      user?.userActivities?.filter(
        (c) => c?.activityType === "USER_PROFILE_CREATED"
      )[0]?.timestamp
    );
  }, [user]);

  const userAccountInfo = [
    {
      label: "First Name",
      value: user?.userProfile?.firstName,
    },
    {
      label: "Last Name",
      value: user?.userProfile?.lastName,
    },
    {
      label: "Email",
      value: user?.userProfile?.email,
      canCopy: true,
    },
    {
      label: "SSO",
      value: user?.userProfile?.ssoUser ? "Yes" : "No",
    },
    {
      label: "UserProfileId",
      value: user?.userProfile?.userProfileId,
      canCopy: true,
    },
    {
      label: "User Type",
      value:
        !!vault && vault?.callingPerson?.vaultRole === "ROLE_VAULT_OWNER"
          ? "Paid"
          : "Free",
    },
    {
      label: "MFA",
      value: user?.userAuth?.mfaList?.length > 0 ? "Enabled" : "Not Enabled",
    },
    {
      label: "User Created",
      value: moment(parseInt(createdAt)).format("M/D/YYYY @ h:mm A"),
    },
    {
      label: "Org Id",
      value: user?.orgUserInfoList?.[0]?.orgId ?? "--",
    },
  ];

  return (
    <>
      <Panel hasPadding>
        <h3 className="mb-3">Account Info</h3>
        <Info infoArray={userAccountInfo} />
      </Panel>
    </>
  );
}

AccountInfo.propTypes = {};

export default AccountInfo;
